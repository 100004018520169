@import 'src/styles/helpers';

.main {
  padding-top: 214px;
  position: relative;

  @include media(tablet) {
    padding-top: 244px;
  }

  @include media(desktopSmall) {
    padding-top: 278px;
  }

  @include media(desktop) {
    padding-top: 276px;
  }

  @include media(desktopBig) {
    padding-top: 292px;
  }

  @include media(desktopHuge) {
    padding-top: 392px;
  }
}

.images {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
  transition: top 0.5s ease;

  .ellipse {
    position: absolute;

    &_draw {
      top: -104px;
      left: -111px;

      @include media(portrait) {
        top: -552px;
        left: -180px;
      }

      @include media(landscape) {
        top: -170px;
        left: -240px;
      }

      @include media(desktopSmall) {
        top: -270px;
        left: -317px;
      }

      @include media(desktop) {
        top: -326px;
        left: -356px;
      }

      @include media(desktopBig) {
        top: -433px;
        left: -279px;
      }

      @include media(desktopHuge) {
        top: -797px;
        left: -389px;
      }
    }

    &_border {
      top: 146px;
      left: 298px;

      @include media(portrait) {
        top: 212px;
        left: 555px;
      }

      @include media(landscape) {
        top: 186px;
        left: 239px;
      }

      @include media(desktopSmall) {
        top: 188px;
        left: 302px;
      }

      @include media(desktop) {
        top: 190px;
        left: 341px;
      }

      @include media(desktopBig) {
        top: 204px;
        left: 552px;
      }

      @include media(desktopHuge) {
        top: 278px;
        left: 874px;
      }
    }

    &_gradient {
      top: 80px;
      left: 184px;

      @include media(portrait) {
        top: 65px;
        left: 343px;
      }

      @include media(landscape) {
        top: 88px;
        left: 174px;
      }

      @include media(desktopSmall) {
        top: 82px;
        left: 204px;
      }

      @include media(desktop) {
        top: 70px;
        left: 230px;
      }

      @include media(desktopBig) {
        top: 67px;
        left: 333px;
      }

      @include media(desktopHuge) {
        top: 95px;
        left: 553px;
      }
    }

    &_noise {
      top: 130px;
      left: -57px;

      @include media(portrait) {
        top: 79px;
        left: -94px;
      }

      @include media(landscape) {
        top: 160px;
        left: -54px;
      }

      @include media(desktopSmall) {
        top: 137px;
        left: -85px;
      }

      @include media(desktop) {
        top: 124px;
        left: -83px;
      }

      @include media(desktopBig) {
        top: 204px;
        left: -49px;
      }

      @include media(desktopHuge) {
        top: 261px;
        left: -66px;
      }
    }
  }
}

.hero {
  padding-bottom: 80px;
  border-bottom: 1px solid;
  @include setProperty(border-color, var(--dark-line-low-accent), var(--light-line-low-accent));
  position: relative;
  z-index: 1;

  @include media(tablet) {
    padding-bottom: 104px;
  }
}

.author {

  @include media(landscape) {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 323px;
    gap: var(--margin-xl);
  }

  @include media(desktopAll) {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 424px;
    gap: var(--margin-xl);
  }

  @include media(desktopHuge) {
    grid-template-columns: 1fr 588px;
  }

  &__mainInfo {
    @include media(landscape) {
      display: grid;
      grid-template-columns: auto 1fr;
      gap: var(--margin-md);
      align-items: center;
    }

    @include media(desktopAll) {
      display: grid;
      grid-template-columns: auto 1fr;
      gap: var(--margin-md);
      align-items: center;
    }
  }

  .avatar {
    width: 142px;
    height: 142px;
    border-radius: 50%;
    position: relative;
    overflow: hidden;
    margin: 0 auto;
    @include setProperty(background, var(--secondary-50), var(--secondary-100));

    img {
      object-fit: cover;
    }

    @include media(landscape) {
      width: 118px;
      height: 118px;
      grid-column: 1 / 2;
      grid-row: 1 / 3;
    }

    @include media(desktopSmall) {
      width: 118px;
      height: 118px;
    }

    @include media(desktop) {
      width: 142px;
      height: 142px;
    }

    @include media(desktopBig) {
      width: 164px;
      height: 164px;
    }

    @include media(desktopHuge) {
      width: 184px;
      height: 184px;
    }

    @include media(desktopAll) {
      grid-column: 1 / 2;
      grid-row: 1 / 3;
    }
  }

  .name {
    margin: var(--margin-md) 0 var(--margin-sm);
    text-align: center;
    text-transform: uppercase;
    font-family: 'KharkivTone', sans-serif;
    font-size: 24px;
    line-height: 32px;

    @include media(portrait) {
      margin-top: var(--margin-lg);
    }

    @include media(landscape) {
      text-align: left;
      margin: 0;
      align-self: flex-end;
      font-size: 28px;
      line-height: 36px;
    }

    @include media(desktopAll) {
      text-align: left;
      margin: 0;
      align-self: flex-end;
      font-size: 40px;
      line-height: 48px;
    }

    @include media(desktopSmall) {
      font-size: 28px;
      line-height: 36px;
    }
  }

  .achievements {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px 28px;
    margin-bottom: var(--margin-lg);
    flex-wrap: wrap;
    font-size: 12px;
    line-height: 1;

    @include media(portrait) {
      margin-bottom: 40px;
    }

    @include media(landscape) {
      justify-content: flex-start;
      margin-bottom: 0;
      align-self: flex-start;
    }

    @include media(desktopAll) {
      justify-content: flex-start;
      margin-bottom: 0;
      align-self: flex-start;
    }

    &__item {

      img {
        margin-right: 8px;
        vertical-align: middle;
      }
    }
  }

  .description {
    font-size: 14px;
    font-weight: 300;
    line-height: 28px;
    text-align: center;

    @include media(landscape) {
      text-align: right;
    }

    @include media(desktopAll) {
      text-align: right;
    }
  }
}

.chips {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  gap: var(--margin-sm) var(--margin-xs);
  margin-top: var(--margin-xxl);

  @include media(portrait) {
    margin-top: 98px;
  }

  @include media(landscape) {
    margin-top: var(--margin-xl);
  }

  @include media(desktopAll) {
    margin-top: var(--margin-xl);
  }

  @include media(desktopHuge) {
    margin-top: 104px;
  }
}

.authorContent {
  padding-top: 52px;
  padding-bottom: 72px;

  @include media(portrait) {
    padding-top: 64px;
    padding-bottom: 104px;
  }

  @include media(landscape) {
    padding-top: 104px;
    padding-bottom: 104px;
  }

  @include media(desktopSmall) {
    padding-top: 104px;
    padding-bottom: 104px;
    grid-template-columns: 1fr 348px;
    gap: 80px;
  }

  @include media(desktop) {
    padding-top: 104px;
    padding-bottom: 104px;
    grid-template-columns: 1fr 360px;
    gap: 120px;
  }

  @include media(desktopBig) {
    padding-top: 120px;
    padding-bottom: 120px;
    grid-template-columns: 1fr 360px;
    gap: 120px;
  }

  @include media(desktopHuge) {
    padding-top: 200px;
    padding-bottom: 200px;
    grid-template-columns: 1fr 424px;
    gap: 244px;
  }

  @include media(desktopAll) {
    display: grid;
    align-items: flex-start;
  }
}

.articles {

  @include media(mobile) {
    padding-bottom: 72px;
  }

  @include media(tablet) {
    padding-bottom: 104px;
  }
}

.articleCard:not(:last-of-type) {
  margin-bottom: 40px;
}

.sidebar {

  @include media(desktopAll) {
    position: sticky;
    top: 16px;
  }
}

.articlesTitle {
  margin-bottom: 56px;
}

.trendingTitle {
  margin-bottom: 32px;

  @include media(tablet) {
    margin-bottom: 40px;
  }

  @include media(desktopSmall) {
    margin-bottom: 32px;
  }

  @include media(desktop) {
    margin-bottom: 32px;
  }

  @include media(desktopBig) {
    margin-bottom: 40px;
  }

  @include media(desktopHuge) {
    margin-bottom: 40px;
  }
}

.trendingContent {
  display: flex;
  width: auto;
  gap: 24px;
  @include hideScrollbar;
  @include scrollOutOfContainer;

  @include media(desktopAll) {
    flex-direction: column;
    gap: 24px;
  }
}

.trendingArticleCard {
  flex-shrink: 0;

  @include media(mobile) {
    width: 249px;
  }

  @include media(portrait) {
    width: 347px;
  }

  @include media(landscape) {
    width: 374px;
  }
}

.otherAuthors {
  margin-top: 72px;
  //margin-bottom: 72px;

  @include media(tablet) {
    margin-top: 98px;
    //margin-bottom: 98px;
  }

  @include media(desktopSmall) {
    margin-top: 104px;
    //margin-bottom: 104px;
  }

  @include media(desktop) {
    margin-top: 120px;
    //margin-bottom: 120px;
  }

  @include media(desktopBig) {
    margin-top: 144px;
    //margin-bottom: 144px;
  }

  @include media(desktopHuge) {
    margin-top: 164px;
    //margin-bottom: 164px;
  }
}

.otherAuthorsContent {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.authorCard {
  padding-bottom: 16px;
  @include setProperty(border-color, var(--secondary-600), var(--secondary-50));
  border-bottom: 1px solid;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 24px;

  &:hover {

    .authorCard__linkIcon {
      @include setProperty(background, var(--secondary-500), var(--secondary-100));
    }
  }

  &__linkIcon {
    border-radius: 24px;
    @include setProperty(background, var(--secondary-700), var(--secondary-50));
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    @include setProperty(color, var(--secondary-300), var(--secondary-400));

    svg {
      width: 20px;
      height: 20px;
    }
  }
}

.categories {
  margin-bottom: 48px;
}

.loadMoreButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100% !important;
  margin-top: var(--margin-xl);
  font-family: 'KharkivTone', sans-serif;

  @keyframes spin {
    from {
      transform: rotate(0)
    }
    to {
      transform: rotate(360deg)
    }
  }

  img {
    animation: spin 1s infinite linear;
  }

  @include media(desktop) {
    margin-top: 56px;
  }

  @include media(desktopBig) {
    margin-top: 56px;
  }

  @include media(desktopHuge) {
    margin-top: 72px;
  }
}
