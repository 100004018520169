@import "src/styles/helpers";

.author {
  display: flex;
  align-items: center;
  gap: 16px;
  letter-spacing: var(--ls-183);

  &.vertical {
    flex-direction: column;
    text-align: center;
  }

  &__avatar {
    border-radius: 50%;
    overflow: hidden;
    @include imgCover;
    width: 68px;
    height: 68px;
    position: relative;
    flex-shrink: 0;
    @include setProperty(background, var(--secondary-50), var(--secondary-100));
    display: block;

    img {
      transition: transform 0.3s ease;
    }
  }

  &__name {
    @include setProperty(color, var(--secondary-50), var(--secondary-500));
    font-family: 'KharkivTone', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    display: inline-block;
    transition: color 0.3s ease;


    @include media(desktopSmall) {
      font-size: 16px;
      line-height: 24px;
    }

    @include media(desktop) {
      font-size: 16px;
      line-height: 24px;
    }

    @include media(desktopBig) {
      font-size: 16px;
      line-height: 24px;
    }

    @include media(desktopHuge) {
      font-size: 16px;
      line-height: 24px;
    }
  }

  &__info {
    @include setProperty(color, var(--secondary-300), var(--secondary-500));
    font-size: 12px;
    font-weight: 300;
    line-height: 24px;
    letter-spacing: 0.18px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 14px;


    @include media(desktopSmall) {
      font-size: 14px;
      line-height: 28px;
      letter-spacing: 0.183px;
    }

    @include media(desktop) {
      font-size: 14px;
      line-height: 28px;
      letter-spacing: 0.183px;
    }

    @include media(desktopBig) {
      font-size: 14px;
      line-height: 28px;
      letter-spacing: 0.183px;
    }

    @include media(desktopHuge) {
      font-size: 14px;
      line-height: 28px;
      letter-spacing: 0.183px;
    }

    @at-root .vertical & {
      justify-content: center;
    }
  }
}

a.author__name:hover {
  text-decoration: underline;
}

a.author__avatar:hover {
  img {
    transform: scale(1.1);
  }
}
